import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AdministrationRestApiService } from '../services/administration-rest-api.service';
import {
	AdministrationFilter,
	AdministrationTemplate,
	AdministrationContact,
	AdministrationAddress,
	AdministrationSender,
	AdministrationFilterField,
	ReferanceOrder,
	AdministrationFilterFolderRule,
	AdministrationGroup,
	AdministrationUser,
} from '../types/com-module';
import {
	FieldTypes,
	FilterFileds,
	FilterOperations,
	Folder,
	Channels,
	Airport,
	MessageTypes,
	ArchiveRules,
	ForwardingRule,
} from '../types/reference';
import { environment } from 'src/environments/environment';
import { NgForm } from '@angular/forms';
import { GlobalI18n } from '@settings/global-i18n';
import { ModuleName } from '@settings/global-settings';
import { FileSaverService } from 'ngx-filesaver';
import { checkValidAddress } from '@shared/functions/validationAddresses';
import { languageList } from '@shared/functions/reference';
import { Urgency } from 'src/app/communication-module-data/reference';
import { SettingsService } from '@core/services/settings/settings.service';

@Component({
	selector: 'app-am-communication',
	templateUrl: './am-communication.component.html',
	styleUrls: ['./am-communication.component.less'],
})
export class AmCommunicationComponent implements OnInit {
	baseURL = environment.apiInformationalURL;

	private privateFilters: Array<AdministrationFilter> = [];
	private privateLetterFolders: Array<Folder> = [];
	private privateContacts: Array<AdministrationContact> = [];
	private privateAddresses: Array<AdministrationAddress> = [];
	private privateSenders: Array<AdministrationSender> = [];
	private privateGroups: Array<AdministrationGroup> = [];
	private privateUsers: Array<AdministrationUser> = [];
	foldersList: Array<Folder> = [];

	fieldTypes: Array<FieldTypes> = [];
	filterFileds: Array<FilterFileds> = [];
	filterOperations: Array<FilterOperations> = [];
	letterFolder: Folder = new Folder();
	channels: Array<Channels> = [];
	airports: Array<Airport> = [];
	messageTypes: Array<MessageTypes> = [];
	filteredMessageTypes: Array<MessageTypes> = [];
	sendersFiltered: Array<Array<AdministrationSender>> = [];
	recipientsFiltered: Array<Array<any>> = [];

	// Данные для вкладки groups - список аэропортов и список прав
	baseAirports: Array<any>; // = [{id: '1', iata: 'ULY', names: 'Ульяновск'}]
	modes: Array<any> = [
		{ id: 1, name: 'readonly' },
		{ id: 2, name: 'full' },
	];

	// Отфильтрованный список папок
	filteredLetterFolder: Array<any> = [];

	defaultTelegramLanguageBindingsList = languageList;

	@Input() filter: AdministrationFilter = new AdministrationFilter();
	@Input() template: AdministrationTemplate = new AdministrationTemplate();
	@Input() contact: AdministrationContact = new AdministrationContact();
	@Input() address: AdministrationAddress = new AdministrationAddress();
	@Input() sender: AdministrationSender = new AdministrationSender();
	@Input() group: AdministrationGroup = new AdministrationGroup();
	@Input() user: AdministrationUser = new AdministrationUser();

	// Для сортировки данных в таблицах
	@Input() order: ReferanceOrder = new ReferanceOrder();

	@ViewChild('filterForm') filterForm: NgForm;
	@ViewChild('folderForm') folderForm: NgForm;
	@ViewChild('templatesForm') templatesForm: NgForm;
	@ViewChild('contactsForm') contactsForm: NgForm;
	@ViewChild('archiveForm') archiveForm: NgForm;
	@ViewChild('addressForm') addressForm: NgForm;
	@ViewChild('senderForm') senderForm: NgForm;
	@ViewChild('groupForm') groupForm: NgForm;
	@ViewChild('userForm') userForm: NgForm;
	@ViewChild('closebutton') closebutton: any;

	activeTab = 'tab-filters';
	activeModule = 'module-communication';
	activeState = 'action-append';
	errorMessage = '';
	errorType = '';
	userAction = '';
	indexDelete: number;
	executableFunction;
	// Выбранное поле чтобы отобржать подсказки
	selectedTemplateInput: number = null;
	// Категории срочности
	urgenciesForChannel: Array<any> = [];

	// Транслитерация
	arrRuLowerCase = [
		'а',
		'б',
		'в',
		'г',
		'д',
		'е',
		'ж',
		'з',
		'и',
		'й',
		'к',
		'л',
		'м',
		'н',
		'о',
		'п',
		'р',
		'с',
		'т',
		'у',
		'ф',
		'х',
		'ц',
		'ь',
		'ы',
		'я',
	];
	arrRuUpperCase = [
		'А',
		'Б',
		'В',
		'Г',
		'Д',
		'Е',
		'Ж',
		'З',
		'И',
		'Й',
		'К',
		'Л',
		'М',
		'Н',
		'О',
		'П',
		'Р',
		'С',
		'Т',
		'У',
		'Ф',
		'Х',
		'Ц',
		'Ь',
		'Ы',
		'Я',
	];
	arrLatUpperCase = [
		'A',
		'B',
		'W',
		'G',
		'D',
		'E',
		'V',
		'Z',
		'I',
		'J',
		'K',
		'L',
		'M',
		'N',
		'O',
		'P',
		'R',
		'S',
		'T',
		'U',
		'F',
		'H',
		'C',
		'X',
		'Y',
		'Q',
	];
	arrLatLowerCase = [
		'a',
		'b',
		'w',
		'g',
		'd',
		'e',
		'v',
		'z',
		'i',
		'j',
		'k',
		'l',
		'm',
		'n',
		'o',
		'p',
		'r',
		's',
		't',
		'u',
		'f',
		'h',
		'c',
		'x',
		'y',
		'q',
	];

	// Дни недели
	daysOfWeek = [
		{ id: 1, nameEn: 'mo', nameRu: 'пн' },
		{ id: 2, nameEn: 'tu', nameRu: 'вт' },
		{ id: 3, nameEn: 'we', nameRu: 'ср' },
		{ id: 4, nameEn: 'th', nameRu: 'чт' },
		{ id: 5, nameEn: 'fr', nameRu: 'пт' },
		{ id: 6, nameEn: 'sa', nameRu: 'сб' },
		{ id: 7, nameEn: 'su', nameRu: 'вс' },
	];

	// Разница с UTC
	today = new Date();
	gmtHours = -this.today.getTimezoneOffset() / 60;

	// Блок переменных для работы ng-select списков
	// Размер отображаемых данных в выпадающем списке
	referanceBufferSize = 50;
	// Количество элементов до конца списка, перед загрузкой новой порции
	numberOfItemsFromEndBeforeFetchingMore = 10;
	// Статус загрузки/обновления справочника для отображения анимации
	// в компоненте ng-select для Направлений
	airportsLoading = false;
	// Подгружаемый список для вывода в выпадающий список
	airportsBuffer = [];
	addressesError = false;

	// Индикатор загрузки
	loading = false;

	// чекбокс удалять ли сообщения в папке
	deleteMessagesFlag = false;

	// Список добавленных типов для настройки архивирования
	messageTypesAdded = new Set();

	// Список добавленных папок для вкладки group
	// (чтобы нельзя было выбрать одну папку в двух строках)
	groupFoldersAdded = new Set();

	// Системные сообщения
	systemMessagesTexts: Array<string> = [];

	constructor(
		public restApi: AdministrationRestApiService,
		private globalI18n: GlobalI18n,
		private fileSaverService: FileSaverService,
		private settingsService: SettingsService
	) {}

	async ngOnInit() {
		await this.loadFoldersList();
		await this.getChannels();
		Promise.all([this.loadFieldTypes(), this.loadFilterOperations()]).then(values => {
			this.loadFilterFileds();
		});
		this.loadFolders();
		this.loadFilters();
		this.getAirports();
		this.systemMessagesTexts['active'] = this.globalI18n.getMessage(ModuleName.ComMan, 'active');
		//this.systemMessagesTexts['notActive'] = this.globalI18n.getMessage(ModuleName.ComMan, 'notActive');
	}

	// Функции прокрутки для Направлений
	onScrollAirports({ end }) {
		if (this.airportsLoading || this.airports.length <= this.airportsBuffer.length) {
			return;
		}

		if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.airportsBuffer.length) {
			this.airportsFetchMore();
		}
	}

	onScrollAirportsToEnd() {
		this.airportsFetchMore();
	}

	// Функция загрузки списка для отображения в ng-select Направлений
	private airportsFetchMore() {
		const len = this.airportsBuffer.length;
		const more = this.airports.slice(len, this.referanceBufferSize + len);
		this.airportsLoading = true;
		this.airportsBuffer = this.airports.concat(more);
		this.airportsLoading = false;
	}

	/**
	 * Функция поиска в выпадающим списке по нескольким параметрам
	 * @param {string} term Строка для поиска введеня пользователем
	 * @param {Airport} item Элемент для поиска
	 */
	customSelectSearchAirport(term: string, item: Airport) {
		term = term.toLowerCase();
		return (
			item.iata.toLowerCase().indexOf(term) > -1 ||
			item.icao.toLowerCase().indexOf(term) > -1 ||
			item.names.toLowerCase().indexOf(term) > -1
		);
	}

	// Filters
	loadFilters() {
		this.privateFilters.length = 0;
		return this.restApi.getFilters().subscribe((data: AdministrationFilter[]) => {
			for (const item of data) {
				const filter = new AdministrationFilter();
				Object.assign(filter, item);
				this.privateFilters.push(filter);
			}
		});
	}

	get filters(): AdministrationFilter[] {
		if (this.activeTab === 'tab-filters') {
			return this.privateFilters
				.slice() // Чтобы исходный массив не менялся
				.sort((a, b) => this.sortObject(a, b));
		} else {
			return this.privateFilters;
		}
	}

	loadFilter(id: string) {
		this.filterForm.form.markAsUntouched();
		this.filterForm.form.markAsPristine();
		this.filter = new AdministrationFilter();
		this.loading = true;
		return this.restApi.getFilter(id).subscribe((data: AdministrationFilter) => {
			this.activeState = 'action-update';
			Object.assign(this.filter, data);
			this.filter.foldersRules = [];
			if (data.foldersRules.length > 0) {
				let id = 0;
				for (const item of data.foldersRules) {
					const rule = new AdministrationFilterFolderRule();
					rule.id = id;
					Object.assign(rule, item);
					if (rule.hours && rule.hours.length > 0) {
						rule.timeStart = this.setTime(rule.timeStart, 'local');
						rule.timeFinish = this.setTime(rule.timeFinish, 'local');
					}
					this.filter.foldersRules.push(rule);
					id++;
				}
			}
			this.loading = false;
		});
	}

	createFilter() {
		this.filterForm.form.markAsUntouched();
		this.filterForm.form.markAsPristine();
		this.activeState = 'action-append';
		this.filter = new AdministrationFilter();
		this.addFilterRule();
	}

	addFilter() {
		this.filterForm.form.markAllAsTouched();
		if (this.filterForm.valid) {
			if (this.filter.conditionsOr.length === 0 && this.filter.conditionsAnd.length === 0) {
				this.errorType = 'error';
				this.errorMessage = this.globalI18n.getMessage(ModuleName.ComMan, 'emptyConditions');
			} else if (this.filter.foldersRules.length === 0) {
				this.errorType = 'error';
				this.errorMessage = this.globalI18n.getMessage(ModuleName.ComMan, 'emptySchedule');
			} else {
				this.loading = true;
				for (const rule of this.filter.foldersRules) {
					if (rule.timeStart && rule.timeFinish) {
						rule.timeStart = this.setTime(rule.timeStart, 'utc');
						rule.timeFinish = this.setTime(rule.timeFinish, 'utc');
					}
				}
				for (let key in this.filter.conditionsOr) {
					if (
						this.filter.conditionsOr[key].fieldId === 0 ||
						this.filter.conditionsOr[key].operationId === 0 ||
						this.filter.conditionsOr[key].value === ''
					) {
						this.filter.conditionsOr.splice(+key, 1);
						key = (+key - 1).toString();
					}
				}
				for (let key in this.filter.conditionsAnd) {
					if (
						this.filter.conditionsAnd[key].fieldId === 0 ||
						this.filter.conditionsAnd[key].operationId === 0 ||
						this.filter.conditionsAnd[key].value === ''
					) {
						this.filter.conditionsAnd.splice(+key, 1);
						key = (+key - 1).toString();
					}
				}
				return this.restApi.addFilter(this.filter).subscribe(
					(data: {}) => {
						this.loading = false;
						this.closebutton.nativeElement.click();
						this.loadFilters();
					},
					err => this.displayError(err)
				);
			}
		}
	}

	selectConditionField(condition, value) {
		condition.fieldId = value;
		condition.operationId = 0;
	}

	setSheduleFolder(num, element) {
		this.filter.foldersRules[num].folderId = element;
		this.filter.foldersRules[num].folderName = this.getById(this.letterFolders, element).name;
	}

	updateFilter(id: number) {
		this.filterForm.form.markAllAsTouched();
		if (this.filterForm.valid) {
			if (this.filter.conditionsOr.length === 0 && this.filter.conditionsAnd.length === 0) {
				this.errorType = 'error';
				this.errorMessage = this.globalI18n.getMessage(ModuleName.ComMan, 'emptyConditions');
			} else if (this.filter.foldersRules.length === 0) {
				this.errorType = 'error';
				this.errorMessage = this.globalI18n.getMessage(ModuleName.ComMan, 'emptySchedule');
			} else {
				this.loading = true;
				for (const rule of this.filter.foldersRules) {
					if (rule.timeStart && rule.timeFinish) {
						rule.timeStart = this.setTime(rule.timeStart, 'utc');
						rule.timeFinish = this.setTime(rule.timeFinish, 'utc');
					}
				}
				for (let key in this.filter.conditionsOr) {
					if (
						this.filter.conditionsOr[key].fieldId === 0 ||
						this.filter.conditionsOr[key].operationId === 0 ||
						this.filter.conditionsOr[key].value === ''
					) {
						this.filter.conditionsOr.splice(+key, 1);
						key = (+key - 1).toString();
					}
				}
				for (let key in this.filter.conditionsAnd) {
					if (
						this.filter.conditionsAnd[key].fieldId === 0 ||
						this.filter.conditionsAnd[key].operationId === 0 ||
						this.filter.conditionsAnd[key].value === ''
					) {
						this.filter.conditionsAnd.splice(+key, 1);
						key = (+key - 1).toString();
					}
				}
				return this.restApi.updateFilter(id, this.filter).subscribe(
					data => {
						this.closebutton.nativeElement.click();
						this.loadFilters();
						this.loading = false;
					},
					err => this.displayError(err)
				);
			}
		}
	}

	deleteFilter(id: number) {
		this.filter.dtRangeFinish = new Date();
		this.loading = true;
		return this.restApi.updateFilter(id, this.filter).subscribe(
			data => {
				this.closebutton.nativeElement.click();
				this.clearErrorMess();
				this.loadFilters();
				this.loading = false;
			},
			err => this.displayError(err)
		);
	}

	addCondition(type: string, condition?) {
		this.filter[type].push(new AdministrationFilterField(condition));
	}

	checkFilter() {
		this.filter.active = !this.filter.active;
	}

	addFilterRule() {
		const rule = new AdministrationFilterFolderRule();
		const length = this.filter.foldersRules.length;
		if (length === 0) {
			rule.id = 0;
		} else {
			rule.id = this.filter.foldersRules[length - 1].id + 1;
		}
		this.filter.foldersRules.push(rule);
	}

	deleteFilterRule() {
		const element = this.filter.foldersRules.filter(el => el.id === this.indexDelete)[0];
		this.deleteElement(this.filter.foldersRules, this.filter.foldersRules.indexOf(element), true);
		this.indexDelete = null;
	}

	// TODO
	conditionDelete(index: number, action = 'delete') {
		this.errorMessage = this.globalI18n.getMessage(ModuleName.ComMan, 'deleteElement');
		this.errorType = 'warning';
		this.userAction = action;
		this.indexDelete = index;
	}

	copyAndTranslateCondition(condition, type) {
		const newCondition = Object.assign({}, condition);
		if (this.isCyrillic(condition.value)) {
			newCondition.value = this.transliterationRuEn(condition.value);
		} else {
			newCondition.value = this.transliterationEnRu(condition.value);
		}
		this.addCondition(type, newCondition);
	}

	async loadFieldTypes() {
		const data = await this.restApi.getFieldTypes();
		Object.assign(this.fieldTypes, data);
	}

	async loadFilterOperations() {
		const data = await this.restApi.getFilterOperations();
		Object.assign(this.filterOperations, data);
	}

	getEnum(id: number, value) {
		let link = '';
		if (id === 10) {
			link = 'message_types';
		} else if (id === 5) {
			link = 'urgencies';
		}
		if (link) {
			return this.restApi.getFilterEnumStatus(link).then(data => {
				this.filterFileds[value].enums = data;
			});
		}
	}

	async loadUrgency() {
		const channel = 1;
		this.urgenciesForChannel.length = 0;
		await this.restApi.getUrgencies(channel).then(data => {
			if (data) {
				this.urgenciesForChannel[channel] = [];
				for (const item of data) {
					const urgency = new Urgency();
					Object.assign(urgency, item);
					this.urgenciesForChannel[channel] = [...this.urgenciesForChannel[channel], urgency];
				}
			}
		});
	}

	loadFilterFileds() {
		this.restApi.getFilterFileds().subscribe((data: FilterFileds) => {
			Object.assign(this.filterFileds, data);
			for (const value in this.filterFileds) {
				for (const element in this.filterFileds[value].operations) {
					this.filterFileds[value].operations[element] = this.getById(
						this.filterOperations,
						this.filterFileds[value].operations[element]
					);
				}
				this.filterFileds[value].fieldType = this.getById(this.fieldTypes, this.filterFileds[value].fieldTypeId).name;
				// Запрос справочника, если тип - список
				if (this.filterFileds[value].fieldTypeId == 3) {
					this.getEnum(this.filterFileds[value].id, value);
				}
			}
		});
	}

	// Folders for list
	loadFoldersList() {
		return new Promise(resolve => {
			this.foldersList.length = 0;
			return this.restApi.getFoldersList().subscribe((data: Folder[]) => {
				for (const item of data) {
					const folder = new Folder();
					Object.assign(folder, item);
					this.foldersList.push(folder);
					resolve(this.foldersList);
				}
			});
		});
	}

	// Folders
	loadFolders() {
		this.privateLetterFolders.length = 0;
		return this.restApi.getFolders().subscribe((data: Folder[]) => {
			for (const item of data) {
				const folder = new Folder();
				Object.assign(folder, item);
				this.privateLetterFolders = [...this.privateLetterFolders, folder];
			}
			this.filterSenders();
			// this.privateLetterFolders = this.privateLetterFolders.filter(el => el.id != 0);
		});
	}

	get letterFolders(): Folder[] {
		if (this.activeTab === 'tab-folders') {
			return this.privateLetterFolders
				.slice() // Чтобы исходный массив не менялся
				.sort((a, b) => this.sortObject(a, b));
		} else {
			return this.privateLetterFolders;
		}
	}

	loadFolder(id: number) {
		this.folderForm.form.markAsUntouched();
		this.folderForm.form.markAsPristine();
		this.letterFolder = new Folder();
		this.loading = true;
		return this.restApi.getFolder(id).subscribe((data: Folder) => {
			this.loading = false;
			this.activeState = 'action-update';
			Object.assign(this.letterFolder, data);
			if (this.letterFolder.maxAge && this.letterFolder.maxAge > 0) {
				this.deleteMessagesFlag = true;
			}
			this.letterFolder.archiveRules = [];
			let id = 0;
			for (const item of data.archiveRules) {
				const rule = new ArchiveRules();
				Object.assign(rule, item);
				rule.id = id;
				this.letterFolder.archiveRules.push(rule);
				id++;
			}
			this.letterFolder.forwardingRules = [];
			if (data.forwardingRules) {
				for (const item of data.forwardingRules) {
					const rule = new ForwardingRule();
					Object.assign(rule, item);

					rule.recipientList = [];
					if (rule.recipient) {
						rule.recipient.split(',').forEach(el => {
							if (el.length !== 0) {
								rule.recipientList = [...rule.recipientList, { name: el, tag: true }];
							}
						});
					}

					this.letterFolder.forwardingRules.push(rule);
				}
			}
			this.filterSenders();
			this.messageTypesAdded = new Set([0]);
			this.filteredMessageTypes = this.messageTypes.slice();
			this.letterFolder.archiveRules.forEach(rule => {
				rule.messageTypeIds.forEach(value => this.messageTypesAdded.add(value));
			});
		});
	}

	createFolder() {
		this.folderForm.form.markAsUntouched();
		this.folderForm.form.markAsPristine();
		this.letterFolder = new Folder();
		this.activeState = 'action-append';
		this.deleteMessagesFlag = false;
		this.filteredMessageTypes = this.messageTypes.slice();
		this.messageTypesAdded = new Set([0]);
	}

	addFolder() {
		this.folderForm.form.markAllAsTouched();
		this.errorMessage = '';
		if (this.folderForm.valid) {
			if (this.errorMessage != '') {
				this.errorMessage += '\n' + this.globalI18n.getMessage(ModuleName.ComMan, 'firstRule');
				this.errorType = 'warning';
				this.userAction = 'repeating_rule';
			} else {
				this.loading = true;
				return this.restApi.addFolder(this.letterFolder).subscribe(
					(data: {}) => {
						this.loading = false;
						this.closebutton.nativeElement.click();
						this.loadFolders();
					},
					err => this.displayError(err)
				);
			}
		}
	}

	addTagFn(name) {
		return { name, tag: true };
	}

	updateFolder(id: number) {
		this.folderForm.form.markAllAsTouched();
		this.errorMessage = '';
		let error = false;

		for (const rule of this.letterFolder.forwardingRules) {
			const recipientsArray = [];
			rule.recipientList.forEach(el => {
				rule.error = checkValidAddress(el.name, rule.channelId, '');
				if (rule.error === '') {
					recipientsArray.push(el.name);
				} else {
					error = true;
				}
			});
			if (!error) {
				rule.recipient = recipientsArray.join(',');
			}
		}

		if (this.folderForm.valid && !error) {
			if (this.errorMessage != '') {
				this.errorMessage += ' ' + this.globalI18n.getMessage(ModuleName.ComMan, 'firstRule');
				this.errorType = 'warning';
				this.userAction = 'repeating_rule';
			} else {
				this.loading = true;
				this.restApi.updateFolder(id, this.letterFolder).subscribe(
					data => {
						this.loading = false;
						this.closebutton.nativeElement.click();
						this.loadFolders();
						this.errorMessage = '';
					},
					err => this.displayError(err)
				);
			}
		}
	}

	onAddType(value, num) {
		this.letterFolder.archiveRules[num].messageTypeNames.push(value.name[0]);
		this.letterFolder.archiveRules[num].messageTypeIds.push(value.id);
		this.messageTypesAdded.add(value.id);
	}

	onRemoveType(value, num) {
		this.letterFolder.archiveRules[num].messageTypeNames = this.letterFolder.archiveRules[num].messageTypeNames.filter(
			el => el != value.label
		);
		this.messageTypesAdded.delete(value.value.id);
	}

	onOpenType() {
		this.filteredMessageTypes = this.messageTypes.filter(el => !this.messageTypesAdded.has(el.id));
	}

	addRule() {
		const rule = new ArchiveRules();
		const length = this.letterFolder.archiveRules.length;
		if (length === 0) {
			rule.id = 0;
		} else {
			rule.id = this.letterFolder.archiveRules[length - 1].id + 1;
		}
		this.letterFolder.archiveRules.push(rule);
	}

	deleteFolderRule() {
		const element = this.letterFolder.archiveRules.filter(el => el.id == this.indexDelete)[0];
		console.log(element);
		element.messageTypeIds.forEach(el => this.messageTypesAdded.delete(el));
		this.deleteElement(this.letterFolder.archiveRules, this.letterFolder.archiveRules.indexOf(element), true);
		this.indexDelete = null;
	}

	addForwardingRule() {
		this.letterFolder.forwardingRules.push(new ForwardingRule());
		console.log(this.letterFolder);
	}

	deleteForwardingRule() {
		this.letterFolder.forwardingRules.splice(this.indexDelete, 1);
		this.indexDelete = null;
		this.clearErrorMess();
	}

	deleteFolder(id: number) {
		this.loading = true;
		this.letterFolder.dtRangeFinish = new Date();
		return this.restApi.updateFolder(id, this.letterFolder).subscribe(
			data => {
				this.closebutton.nativeElement.click();
				this.clearErrorMess();
				this.loadFolders();
				this.loading = false;
			},
			err => this.displayError(err)
		);
	}

	// Templates
	/*
  loadTemplates() {
    this.privateTemplates.length = 0;
    return this.restApi.getTemplates().subscribe((data: AdministrationTemplate[]) => {
      for (const item of data) {
        const template = new AdministrationTemplate();
        Object.assign(template, item);
        this.privateTemplates.push(template);
      }
    });
  }

  get templates(): AdministrationTemplate[] {
    if (this.activeTab == 'tab-templates') {
      return this.privateTemplates
        .slice() // Чтобы исходный массив не менялся
        .sort((a, b) => this.sortObject(a, b));
    } else
      return this.privateTemplates;
  }


  loadTemplate(id: number) {
    this.templatesForm.form.markAsUntouched();
    this.templatesForm.form.markAsPristine();
    this.loading = true;
    return this.restApi.getTemplate(id).subscribe((data: AdministrationTemplate) => {
      this.loading = false;
      this.activeState = 'action-update';
      Object.assign(this.template, data);
      this.selectedTemplateInput = null;
    });
  }

  createTemplate() {
    this.activeState = 'action-append';
    this.templatesForm.form.markAsUntouched();
    this.templatesForm.form.markAsPristine();
    this.template = new AdministrationTemplate();
    this.template.parts[0] = new AdministrationTemplateParts();
    this.selectedTemplateInput = null;
  }

  addTemplate() {
    if ((this.template.dtRangeStart) && (this.template.dtRangeFinish) &&
      (this.template.dtRangeStart >= this.template.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(ModuleName.ComMan, 'dateStartGreatDataFinish');
    } else {
      this.templatesForm.form.markAllAsTouched();
      if (this.templatesForm.valid) {
        this.loading = true;
        this.restApi.addTemplate(this.template).subscribe((data: {}) => {
          this.loading = false;
          this.closebutton.nativeElement.click();
          this.loadTemplates();
        }, err => this.displayError(err));
      }
    }
  }

  updateTemplate(id: number) {
    if ((this.template.dtRangeStart) && (this.template.dtRangeFinish) &&
      (this.template.dtRangeStart >= this.template.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(ModuleName.ComMan, 'dateStartGreatDataFinish');
    } else if (this.template.parts.length == 1 &&
              this.template.parts[0].type == '' &&
              this.template.parts[0].value == '') {
      this.errorMessage = this.globalI18n.getMessage(ModuleName.ComMan, 'templateMustContainOneField');
    } else {
      this.loading = true;
      this.templatesForm.form.markAllAsTouched();
      if (this.templatesForm.valid) {
        this.restApi.updateTemplate(id, this.template).subscribe(data => {
          this.loading = false;
          this.closebutton.nativeElement.click();
          this.loadTemplates();
        }, err => this.displayError(err));
      }
    }
  }

  deleteTemplate(id: number) {
    this.loading = true;
    this.template.dtRangeFinish = new Date();
    return this.restApi.updateTemplate(id, this.template).subscribe(data => {
      this.closebutton.nativeElement.click();
      this.clearErrorMess();
      this.loadTemplates();
      this.loading = false;
    }, err => this.displayError(err));
  }

  addPart() {
    this.selectedTemplateInput = this.template.parts.length;
    this.template.parts[this.template.parts.length] = new AdministrationTemplateParts();
  }
  */

	attemptDelete(index: number) {
		this.errorMessage = this.globalI18n.getMessage(ModuleName.ComMan, 'deleteElement');
		this.errorType = 'warning';
		this.userAction = 'delete';
		this.indexDelete = index;
	}

	deleteElement(array: any, index: number, canDelete = false) {
		if (array.length === 1 && !canDelete) {
			this.errorMessage = this.globalI18n.getMessage(ModuleName.ComMan, 'cantRemoveLastItem');
			this.errorType = 'warning';
			this.userAction = '';
		} else {
			array.splice(index, 1);
			this.errorMessage = this.errorType = this.userAction = '';
			this.selectedTemplateInput = null;
		}
	}

	clearErrorMess() {
		this.errorMessage = '';
		this.errorType = '';
		this.userAction = '';
	}

	selectTemplateInput(index: number) {
		this.selectedTemplateInput = index;
	}

	// Contacts
	loadContacts() {
		this.privateContacts.length = 0;
		return this.restApi.getContacts().subscribe((data: AdministrationContact[]) => {
			for (const item of data) {
				const contact = new AdministrationContact();
				Object.assign(contact, item);
				this.privateContacts.push(contact);

				const recipient = { id: item.id, name: item.name, addresses: [], channelId: item.channelId };
				item.addresses.forEach((value, index) => {
					// Формирование идентификатора вложенных элементов:
					// берется родительский идентификатор (он имеется с бэка)
					// к нему добавляется текущий индекс элемента в массиве
					const childId = recipient.id.toString() + index.toString();
					recipient.addresses.push({ id: childId, name: value });
				});
				this.recipientsFiltered[item.channelId] = this.recipientsFiltered[item.channelId]
					? [...this.recipientsFiltered[item.channelId], recipient]
					: [recipient];
			}
		});
	}

	get contacts(): AdministrationContact[] {
		if (this.activeTab == 'tab-contacts') {
			return this.privateContacts
				.slice() // Чтобы исходный массив не менялся
				.sort((a, b) => this.sortObject(a, b));
		} else {
			return this.privateContacts;
		}
	}

	loadContact(id: number) {
		this.contactsForm.form.markAsUntouched();
		this.contactsForm.form.markAsPristine();
		this.contact = new AdministrationContact();
		this.loading = true;
		return this.restApi.getContact(id).subscribe((data: AdministrationContact) => {
			this.loading = false;
			this.activeState = 'action-update';
			Object.assign(this.contact, data);
		});
	}

	createContact() {
		// ???
		// this.addressesError = false;
		this.contactsForm.form.markAsUntouched();
		this.contactsForm.form.markAsPristine();
		this.activeState = 'action-append';
		this.contact = new AdministrationContact();
		// this.template.parts[0] = new AdministrationTemplateParts();
	}

	addContact() {
		this.contactsForm.form.markAllAsTouched();
		if (this.contactsForm.valid) {
			// непонятно зачем эта проверка, если поля сделаны обязательными в форме
			/* if (this.checkAddressesIsEmpty(this.contact.addresses)) {
        this.addressesError = this.checkAddressesIsEmpty(this.contact.addresses);
        return;
      } else {
        this.addressesError = false;
      }*/
			this.loading = true;
			return this.restApi.addContact(this.contact).subscribe(
				(data: {}) => {
					this.loading = false;
					this.closebutton.nativeElement.click();
					this.loadContacts();
				},
				err => this.displayError(err)
			);
		}
	}

	/*checkAddressesIsEmpty(addresses) {
    addresses.forEach(address => {
      if (address.length === 0) {
        return true;
      }
    });
    return false;
  }*/

	updateContact(id: number) {
		// непонятно зачем эта проверка, если поля сделаны обязательными в форме
		/*if (this.checkAddressesIsEmpty(this.contact.addresses)) {
      this.addressesError = this.checkAddressesIsEmpty(this.contact.addresses);
      return;
    } else {
      this.addressesError = false;
    }*/
		this.contactsForm.form.markAllAsTouched();
		if (this.contactsForm.valid) {
			this.loading = true;
			this.restApi.updateContact(id, this.contact).subscribe(
				data => {
					this.loading = false;
					this.closebutton.nativeElement.click();
					this.loadContacts();
				},
				err => this.displayError(err)
			);
		}
	}

	deleteContact(id: number) {
		this.loading = true;
		this.contact.dtRangeFinish = new Date();
		return this.restApi.updateContact(id, this.contact).subscribe(
			data => {
				this.closebutton.nativeElement.click();
				this.clearErrorMess();
				this.loadContacts();
				this.loading = false;
			},
			err => this.displayError(err)
		);
	}

	addContactAdress() {
		if (this.contact.addresses) {
			this.contact.addresses[this.contact.addresses.length] = '';
		} else {
			this.contact.addresses = [''];
		}
	}

	// Addresses
	loadAddresses() {
		this.privateAddresses.length = 0;
		return this.restApi.getAddresses().subscribe((data: AdministrationAddress[]) => {
			for (const item of data) {
				const address = new AdministrationAddress();
				Object.assign(address, item);
				this.privateAddresses.push(address);
			}
		});
	}

	get addresses(): AdministrationAddress[] {
		if (this.activeTab == 'tab-addresses') {
			return this.privateAddresses
				.slice() // Чтобы исходный массив не менялся
				.sort((a, b) => this.sortObject(a, b));
		} else {
			return this.privateAddresses;
		}
	}

	loadAddress(id: number) {
		this.addressForm.form.markAsUntouched();
		this.addressForm.form.markAsPristine();
		this.address = new AdministrationAddress();
		this.loading = true;
		return this.restApi.getAddress(id).subscribe((data: AdministrationAddress) => {
			this.loading = false;
			this.activeState = 'action-update';
			Object.assign(this.address, data);
		});
	}

	createAddress() {
		this.activeState = 'action-append';
		this.addressForm.form.markAsUntouched();
		this.address = new AdministrationAddress();
	}

	addAddress() {
		this.addressForm.form.markAllAsTouched();
		this.addressForm.form.markAsPristine();
		if (this.addressForm.valid) {
			this.loading = true;
			return this.restApi.addAddress(this.address).subscribe(
				(data: {}) => {
					this.loading = false;
					this.closebutton.nativeElement.click();
					this.loadAddresses();
				},
				err => this.displayError(err)
			);
		}
	}

	updateAddress(id: number) {
		this.addressForm.form.markAllAsTouched();
		if (this.addressForm.valid) {
			this.loading = true;
			this.restApi.updateAddress(id, this.address).subscribe(
				data => {
					this.loading = false;
					this.closebutton.nativeElement.click();
					this.loadAddresses();
				},
				err => this.displayError(err)
			);
		}
	}

	deleteAddress(id: number) {
		this.loading = true;
		this.address.dtRangeFinish = new Date();
		return this.restApi.updateAddress(id, this.address).subscribe(
			data => {
				this.closebutton.nativeElement.click();
				this.clearErrorMess();
				this.loadAddresses();
				this.loading = false;
			},
			err => this.displayError(err)
		);
	}

	addAddressesAdress() {
		if (this.address.addresses) {
			this.address.addresses[this.address.addresses.length] = '';
		} else {
			this.address.addresses = [''];
		}
	}

	// Senders
	loadSenders() {
		this.privateSenders = [];
		return this.restApi.getSenders().subscribe((data: AdministrationSender[]) => {
			for (const item of data) {
				const sender = new AdministrationSender();
				Object.assign(sender, item);
				this.privateSenders.push(sender);
			}
		});
	}

	get senders(): AdministrationSender[] {
		if (this.activeTab == 'tab-senders') {
			return this.privateSenders
				.slice() // Чтобы исходный массив не менялся
				.sort((a, b) => this.sortObject(a, b));
		} else {
			return this.privateSenders;
		}
	}

	loadSender(id: number) {
		this.senderForm.form.markAsUntouched();
		this.senderForm.form.markAsPristine();
		this.sender = new AdministrationSender();
		this.loading = true;
		return this.restApi.getSender(id).subscribe((data: AdministrationSender) => {
			this.loading = false;
			this.activeState = 'action-update';
			Object.assign(this.sender, data);
		});
	}

	createSender() {
		this.activeState = 'action-append';
		this.senderForm.form.markAsUntouched();
		this.senderForm.form.markAsPristine();
		this.sender = new AdministrationSender();
	}

	addSender() {
		this.senderForm.form.markAllAsTouched();
		if (this.senderForm.valid) {
			this.loading = true;
			if (this.sender.channelId === 1) {
				this.sender.addressLocal = this.transliterationEnRu(this.sender.addressEnglish);
			}
			this.sender.baseApId =  this.settingsService.general.getHomeAirport();
			this.sender.folderId = +this.sender.folderId;
			return this.restApi.addSender(this.sender).subscribe(
				(data: {}) => {
					this.loading = false;
					this.closebutton.nativeElement.click();
					this.loadSenders();
				},
				err => this.displayError(err)
			);
		}
	}

	updateSender(id: number, confirmed = false) {
		this.errorMessage = this.errorType = this.userAction = '';
		this.senderForm.form.markAllAsTouched();
		if (this.senderForm.valid) {
			this.loading = true;
			this.sender.folderId = +this.sender.folderId;
			if (this.sender.channelId === 1) {
				this.sender.addressLocal = this.transliterationEnRu(this.sender.addressEnglish);
			}
			this.restApi.updateSender(id, this.sender, confirmed).subscribe(
				data => {
					this.loading = false;
					this.closebutton.nativeElement.click();
					this.loadSenders();
				},
				err => this.displayError(err)
			);
		}
	}

	deleteSender(id: number) {
		this.loading = true;
		this.sender.dtRangeFinish = new Date();
		return this.restApi.updateSender(id, this.sender).subscribe(
			data => {
				this.closebutton.nativeElement.click();
				this.clearErrorMess();
				this.loadSenders();
				this.loading = false;
			},
			err => this.displayError(err)
		);
	}

	// Groups
	loadGroups() {
		this.privateGroups.length = 0;
		return this.restApi.getGroups().subscribe((data: AdministrationGroup[]) => {
			for (const item of data) {
				const group = new AdministrationGroup();
				Object.assign(group, item);
				this.privateGroups = [...this.privateGroups, group];
			}
		});
	}

	get groups(): AdministrationGroup[] {
		if (this.activeTab == 'tab-groups') {
			return this.privateGroups
				.slice() // Чтобы исходный массив не менялся
				.sort((a, b) => this.sortObject(a, b));
		} else {
			return this.privateGroups;
		}
	}

	loadGroup(id: number) {
		this.groupForm.form.markAsUntouched();
		this.groupForm.form.markAsPristine();
		this.loading = true;
		this.group = new AdministrationGroup();
		return this.restApi.getGroup(id).subscribe((data: AdministrationGroup) => {
			this.loading = false;
			this.activeState = 'action-update';
			Object.assign(this.group, data);
			this.filterFolderForGroup();
			this.group.folders.forEach((folder, index) => {
				folder.hideid = index;
			});
		});
	}

	createGroup() {
		this.activeState = 'action-append';
		this.groupForm.form.markAsUntouched();
		this.groupForm.form.markAsPristine();
		this.group = new AdministrationGroup();
		this.groupFoldersAdded = new Set();
		this.filteredLetterFolder = this.letterFolders.slice();
		this.group.baseAps = [this.settingsService.general.getHomeAirport().toString()];
		this.filteredLetterFolder.forEach(el => {
			el.id = el.id.toString();
		});
	}

	addGroup() {
		this.groupForm.form.markAllAsTouched();
		if (this.groupForm.valid) {
			this.loading = true;
			return this.restApi.addGroup(this.group).subscribe(
				(data: {}) => {
					this.loading = false;
					this.closebutton.nativeElement.click();
					this.loadGroups();
				},
				err => this.displayError(err)
			);
		}
	}

	updateGroup(id: string) {
		this.groupForm.form.markAllAsTouched();
		if (this.groupForm.valid) {
			this.loading = true;
			this.restApi.updateGroup(id, this.group).subscribe(
				() => {
					this.loading = false;
					this.closebutton.nativeElement.click();
					this.loadGroups();
				},
				err => this.displayError(err)
			);
		}
	}

	addGroupFolder() {
		const length = this.group.folders.length;
		if (length > 0) {
			const hideid = this.group.folders[this.group.folders.length - 1].hideid + 1;
			this.group.folders.push({
				id: null,
				mode: null,
				hideid,
			});
		} else {
			this.group.folders = [
				{
					id: null,
					mode: null,
					hideid: 0,
				},
			];
		}
	}

	deleteGroupFolder() {
		this.group.folders = this.group.folders.filter(folder => this.indexDelete != folder.hideid);
		this.clearErrorMess();
	}

	onOpenFolder() {
		this.filterFolderForGroup();
		this.filteredLetterFolder = this.letterFolders.filter(el => !this.groupFoldersAdded.has(el.id));
	}

	filterFolderForGroup() {
		this.groupFoldersAdded = new Set();
		this.filteredLetterFolder = this.letterFolders.slice();
		this.filteredLetterFolder.forEach(el => {
			el.id = el.id.toString();
		});
		this.group.folders.forEach(folder => {
			this.groupFoldersAdded.add(folder.id);
		});
	}

	// Users
	loadUsers() {
		this.privateUsers = [];
		return this.restApi.getUsers().subscribe((data: AdministrationUser[]) => {
			for (const item of data) {
				const user = new AdministrationUser();
				Object.assign(user, item);
				this.privateUsers.push(user);
			}
		});
	}

	get users(): AdministrationUser[] {
		if (this.activeTab == 'tab-users') {
			return this.privateUsers
				.slice() // Чтобы исходный массив не менялся
				.sort((a, b) => this.sortObject(a, b));
		} else {
			return this.privateUsers;
		}
	}

	loadUser(id: number) {
		this.userForm.form.markAsUntouched();
		this.userForm.form.markAsPristine();
		this.loading = true;
		this.user = new AdministrationUser();
		return this.restApi.getUser(id).subscribe((data: AdministrationUser) => {
			this.loading = false;
			this.activeState = 'action-update';
			Object.assign(this.user, data);
			this.user.groupsArray = [];
			this.user.groups.forEach(element => {
				this.user.groupsArray.push(element.id);
			});
		});
	}

	createUser() {
		this.activeState = 'action-append';
		this.userForm.form.markAsUntouched();
		this.userForm.form.markAsPristine();
		this.user = new AdministrationUser();
	}

	addUser() {
		this.userForm.form.markAllAsTouched();
		if (this.userForm.valid) {
			this.loading = true;
			this.user.groupsArray.forEach(element => {
				const group = new AdministrationGroup();
				group.id = element;
				this.user.groups.push(group);
			});
			return this.restApi.addUser(this.user).subscribe(
				(data: {}) => {
					this.loading = false;
					this.closebutton.nativeElement.click();
					this.loadUsers();
				},
				err => this.displayError(err)
			);
		}
	}

	updateUser(id: string) {
		this.userForm.form.markAllAsTouched();
		if (this.userForm.valid) {
			this.loading = true;
			this.user.groups = [];
			this.user.groupsArray.forEach(element => {
				const group = new AdministrationGroup();
				group.id = element.toString();
				this.user.groups.push(group);
			});
			this.restApi.updateUser(id, this.user).subscribe(
				() => {
					this.loading = false;
					this.closebutton.nativeElement.click();
					this.loadUsers();
				},
				err => this.displayError(err)
			);
		}
	}

	// Channels
	getChannels() {
		return new Promise(resolve => {
			this.restApi.getChannels().subscribe((data: Channels) => {
				Object.assign(this.channels, data);
				resolve(this.channels);
			});
		});
	}

	// Airports
	getAirports() {
		return this.restApi.getAirports().subscribe((data: Airport[]) => {
			this.baseAirports = [];
			for (const item of data) {
				const airport = new Airport();
				Object.assign(airport, item);
				this.airports = [...this.airports, airport];
				if (airport.id == this.settingsService.general.getHomeAirport()) {
					const homeAirport = { id: airport.id.toString(), iata: airport.iata, names: airport.names };
					this.baseAirports = [...this.baseAirports, homeAirport];
				}
			}
		});
	}

	// Message Types
	getMessageTypes() {
		this.messageTypes.length = 0;
		return this.restApi.getMessageTypes().subscribe((data: MessageTypes[]) => {
			for (const item of data) {
				const type = new MessageTypes();
				Object.assign(type, item);
				this.messageTypes = [...this.messageTypes, type];
				this.filteredMessageTypes = [...this.messageTypes, type];
			}
		});
	}

	changeTab(event: { target: { id: string } }) {
		if (this.activeTab === event.target.id) {
			return;
		}
		this.activeTab = event.target.id;
		this.order = new ReferanceOrder();
		this.activeState = 'action-append';
		switch (this.activeTab) {
			case 'tab-filters': {
				this.loadFoldersList();
				this.loadFilters();
				break;
			}
			case 'tab-folders': {
				this.loadSenders();
				this.loadContacts();
				this.getMessageTypes();
				this.loadUrgency();
				this.loadFolders();
				break;
			}
			/*case 'tab-templates': {
        this.loadTemplates();
        break;
      }*/
			case 'tab-contacts': {
				this.loadContacts();
				this.getChannels();
				break;
			}
			case 'tab-addresses': {
				this.loadAddresses();
				break;
			}
			case 'tab-senders': {
				this.loadFoldersList();
				this.loadSenders();
				break;
			}
			case 'tab-groups': {
				this.loadFoldersList();
				this.loadGroups();
				break;
			}
			case 'tab-users': {
				this.loadGroups();
				this.loadUsers();
				break;
			}
		}
	}

	showTab(item: string): boolean {
		if (item === this.activeTab) {
			return true;
		} else {
			return false;
		}
	}

	showButton(item: string): boolean {
		if (item === this.activeState) {
			return true;
		} else {
			return false;
		}
	}

	parseDate(dateString: string): Date {
		if (dateString !== '') {
			return new Date(dateString);
		}
		return null;
	}

	sortById(arr) {
		arr.sort((a, b) => (a.id > b.id ? 1 : -1));
	}

	getById(arr, search) {
		for (const value in arr) {
			if (arr[value].id === search) {
				return arr[value];
			}
		}
		return null;
	}

	// Чтобы не терялся фокус при вводе адресов
	trackByFn(index: any, item: any) {
		return index;
	}

	updateArray(array: Array<any>, id: number) {
		const index = array.indexOf(id);
		if (index > -1) {
			array.splice(index, 1);
		} else {
			array.push(id);
		}
	}

	filterSenders() {
		this.sendersFiltered['aftn'] = this.privateSenders.slice().filter(el => {
			if (el.channelId == 1 && el.folderId == this.letterFolder.id) {
				return el;
			}
		});
		this.sendersFiltered['sitatex'] = this.privateSenders.slice().filter(el => {
			if (el.channelId == 2 && el.folderId == this.letterFolder.id) {
				return el;
			}
		});
		this.sendersFiltered['email'] = this.privateSenders.slice().filter(el => {
			if (el.channelId == 3 && el.folderId == this.letterFolder.id) {
				return el;
			}
		});
		console.log('this.sendersFiltered', this.sendersFiltered);
	}

	transliterationEnRu(text) {
		if (text) {
			const messageArray = text.split('');
			let transliterationMessage = '';
			let arrayIndex = -1;
			messageArray.forEach(element => {
				arrayIndex = this.arrLatLowerCase.indexOf(element);
				if (arrayIndex === -1) {
					arrayIndex = this.arrLatUpperCase.indexOf(element);
					if (arrayIndex === -1) {
						transliterationMessage += element;
					} else {
						transliterationMessage += this.arrRuUpperCase[arrayIndex];
					}
				} else {
					transliterationMessage += this.arrRuLowerCase[arrayIndex];
				}
			});
			text = transliterationMessage;
			return text;
		} else {
			return '';
		}
	}

	transliterationRuEn(text): string {
		if (text) {
			text = text.toUpperCase();
			const messageArray = text.split('');
			let transliterationMessage = '';
			let arrayIndex = -1;
			messageArray.forEach(element => {
				// Проверка на совпадение с допустимыми русскими буквами
				arrayIndex = this.arrRuUpperCase.indexOf(element);
				// Если нет совпадения с русскими, то добавляем элемент в строку
				if (arrayIndex === -1) {
					// Перед добавлением проверяем не является ли элемент русским символом
					// которого нет в допустимых (Ш,Щ и прочие)
					if (!this.isCyrillic(element)) {
						// Если символ коректный то добовляем в строку
						transliterationMessage += element;
					} else {
						// Если символ русский недопустимый, то меняем на прочерк, что бы
						// пользователь увидел что ввод данного символа недопустим
						transliterationMessage += '-';
					}
				} else {
					// Если найден один из допустимых символов в русском алфавите,
					// то меняем его на соответствующий переводу латинский символ
					transliterationMessage += this.arrLatUpperCase[arrayIndex];
				}
			});
			return transliterationMessage;
		} else {
			return '';
		}
	}

	isCyrillic(text) {
		return /[А-Я]/i.test(text);
	}

	textUppercase(value: string) {
		return value.toUpperCase();
	}

	/**
	 * Функция обработки изменения текущей сортировки столбца
	 *
	 * @param element Текущий столбец по которому нажал пользователь
	 * @param parent Блок заголовков со всеми столбцами таблицы
	 */
	sortBy(element, parent) {
		const order = element.getAttribute('data-method');
		// Сохранение имени поля из столбца, по которому применять фильтр
		this.order.orderField = element.getAttribute('field');
		parent.querySelectorAll('th').forEach(n => n.classList.remove('asc'));
		parent.querySelectorAll('th').forEach(n => n.classList.remove('desc'));
		parent.querySelectorAll('th').forEach(n => n.setAttribute('data-method', ''));
		if (order == '') {
			// По возрастанию
			element.setAttribute('data-method', 'asc');
			element.classList.add('asc');
			this.order.orderBy = 'asc';
		} else if (order == 'asc') {
			// По убыванию
			element.setAttribute('data-method', 'desc');
			element.classList.add('desc');
			this.order.orderBy = 'desc';
		} else if (order == 'desc') {
			// Без сортировки
			// Отчистка имени поля по которому применять фильтр, показ данных как были полученны
			this.order.orderField = '';
			this.order.orderBy = '';
		}
	}

	sortObject(a, b) {
		if (this.order.orderBy == 'asc') {
			if (typeof a[this.order.orderField] == 'number') {
				return a[this.order.orderField] > b[this.order.orderField] ? 1 : -1;
			} else {
				return a[this.order.orderField].toLowerCase() > b[this.order.orderField].toLowerCase() ? 1 : -1;
			}
		} else if (this.order.orderBy == 'desc') {
			if (typeof a[this.order.orderField] == 'number') {
				return a[this.order.orderField] < b[this.order.orderField] ? 1 : -1;
			} else {
				return a[this.order.orderField].toLowerCase() < b[this.order.orderField].toLowerCase() ? 1 : -1;
			}
		}
	}

	displayError(err) {
		this.loading = false;
		if (err.status === 409) {
			this.errorMessage = err.message;
			this.errorType = 'warning';
			this.userAction = 'update_sender';
		} else if (err.type) {
			this.errorType = err.type;
			this.errorMessage = err.message;
			console.log('Error: ' + err.message + '\ndetail:' + err.detail);
		} else {
			this.errorMessage = err;
		}
	}

	downloadFile(name: string, type: string) {
		const link = name + '?export=' + type;
		return this.restApi.downloadFile(link).subscribe(data => {
			const blob = new Blob([data], { type: 'application' });
			this.fileSaverService.save(blob, name + '.' + type);
		});
	}

	confirmAction(func: Function) {
		this.userAction = 'deleteItem';
		this.errorType = 'warning';
		this.errorMessage = this.globalI18n.getMessage(ModuleName.ComMan, 'deleteItem'); // 'Delete?';
		this.executableFunction = func;
	}

	/**
	 * Функция преобразования времени в utc и наоборот
	 * @param {string} time Строка со временем
	 * @param {string} timezone 'local' or 'utc'
	 */
	setTime(time: string, timezone: string) {
		const today = new Date();
		const timeArray = time.split(':');
		const thisDate = new Date(
			today.getFullYear(),
			today.getMonth(),
			today.getDate(),
			+timeArray[0],
			+timeArray[1],
			0,
			0
		);
		if (timezone == 'local') {
			thisDate.setMinutes(thisDate.getMinutes() + -today.getTimezoneOffset());
		} else if (timezone == 'utc') {
			thisDate.setMinutes(thisDate.getMinutes() + today.getTimezoneOffset());
		}
		return thisDate.getHours().toString().padStart(2, '0') + ':' + thisDate.getMinutes().toString().padStart(2, '0');
	}

	closeWithoutSaving() {
		switch (this.activeTab) {
			case 'tab-folders':
				this.closebutton.nativeElement.click();
				break;
			default:
				break;
		}
		this.errorMessage = '';
	}
}
